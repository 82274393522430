
import React from "react";
import './icons.css'
import './style.css'
import './theme.css'

function App() {
  return (
    <div className="fx-centered fx-col fx-start-h body">
      <div className="logo"></div>
      <div className="header-text"></div>
      <p className="sub-header">Bientôt disponible</p>
      <div className="footer"></div>
    </div>
  );
}

export default App;
